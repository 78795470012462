import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { deleteCookie } from '../CommonSetting/Config';
import { CIcon } from '@coreui/icons-react';
import { cilPowerStandby } from '@coreui/icons';
function NavMenu() {
    const navigate = useNavigate();
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding:"20px 0px" }}>

            <div class="container">
               <div className='header d-md-flex align-items-center justify-content-between'>
                <div className="d-flex align-items-center justify-content-between">
                        <img src='./logo_final.svg' style={{ width: '160px' }} mx="auto" />
                        <div className="badge bg-danger rounded-full d-lg-none" onClick={async () => {
                            await deleteCookie('affiliateLoginToken')
                            await deleteCookie('affiliateLoginUserCode')
                            await deleteCookie('affiliateUserName')
                            await deleteCookie('affiliateEmail')
                            navigate("/")
                        }}>
                            <CIcon icon={cilPowerStandby} style={{ height: '17px', width: '17px' }} />
                        </div>
                </div>
                <div className=' d-flex align-items-center justify-content-between gap-md-3 gap-1 mt-3 mt-md-0 menu'>
                    <NavLink to='/home/'>Home</NavLink>
                    <NavLink to='/home/Payments'>Payments</NavLink>
                        <NavLink to='/home/Transaction'>Transaction</NavLink>
                        <NavLink to='/home/Settings'>Settings</NavLink>
                    <p className='bg-danger mb-0 logout' onClick={async () => {
                        await deleteCookie('affiliateLoginToken')
                        await deleteCookie('affiliateLoginUserCode')
                        await deleteCookie('affiliateUserName')
                        await deleteCookie('affiliateEmail')
                        navigate("/")
                    }}>Logout</p>
                </div>
            </div >
            </div>
            
        </div>
    )
}

export default NavMenu