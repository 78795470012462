import { DraftsRounded } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { Button } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker/DatePicker';
import React from 'react'
import { Badge, Col, Row } from 'react-bootstrap'
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ApiCall, dateFormateWithTime, getCookie, handleDownloadExcel, AlertBox } from '../../CommonSetting/Config';
import { useEffect } from 'react';
import { CommonTable } from './CommonTable';
import { useDispatch } from 'react-redux';
import { affliateSessionExpire } from '../../redux/authSlice';
import { yellow } from '@mui/material/colors';
import { NewTable } from '../Common/NewTable';
import { Link } from 'react-router-dom';
const Hometab = () => {
    const [getUserInfoDetails, setUserInfoDetails] = useState([]);
    const [getSummaryDetails, setSummaryDetails] = useState([])
    const [text, setText] = useState(""); // For input text
    const [copiedText, setCopiedText] = useState(""); // To display the copied text
    const [isLoading, setIsLoading] = useState(false);
    const affiliateEmail = getCookie('affiliateEmail')
    const [buttonText, setButtonText] = useState("Copy"); // Button text state
    const [formData, setFormData] = useState({
        fromDate: dayjs(new Date()),
        toDate: dayjs(new Date()),
    });

    const [tableData, settableData] = useState([]);
    const dispatch = useDispatch()


    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ sortColumn: null, sortOrder: null });
    const [pageSize, setPageSize] = useState("10");
    const [filterData, setFilterData] = useState({
        type: "S",
        searchText: "",
        searchFieldValue: "",
        startValue: "",
        AdvanceFilter: []
    })
    const [fileName, setFileName] = useState()
    const [tabType, setTabType] = useState('')

    const copyToClipboard = async () => {
        try {
            console.log(getUserInfoDetails.refferelCode)
            await navigator.clipboard.writeText(getUserInfoDetails.refferelCode);
            setButtonText("Copied!"); // Change button text
            setTimeout(() => setButtonText("Copy"), 3000); 
        } catch (err) {
        }
    };

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    const handleChange1 = (event, pagenumber) => {
        setCurrentPageNo(pagenumber)
    }


    const getDashboardDataDetails = async () => {

        let request = {
            ...formData,
            fromDate: dayjs(formData.fromDate).format('DD-MM-YYYY'),
            toDate: dayjs(formData.toDate).format('DD-MM-YYYY')
        }

        try {
            setIsLoading(true)
            const response = await ApiCall(`/api/Affiliate/GetDashboardDetails`, request);
            if (response.status === 'SUCCESS') {
                setUserInfoDetails(response.userDetails);
                setSummaryDetails(response.summaryDetails);
                setIsLoading(false)
            } else if (response.status === 'ERROR') {
                setUserInfoDetails([])
                toast.error(response.message);
                setIsLoading(false)
            } else if (response.status === "EXPIRED") {
                dispatch(affliateSessionExpire({
                    affliateSessionExpires: true,
                    status: response.status,
                    message: response.message
                }))
            }
        } catch (error) {
            toast.error('There was a problem with the server, Please try again later');
        }
        finally {
            setIsLoading(false)
        }
    };
    const getHomeTabDetails = async (TabType, e) => {
        setTabType(TabType)
        let request = {
            ...filterData,
            ...sortConfig,
            pageIndex: `${currentPageNo}`,
            pageSize: pageSize,
            TabType: TabType
        }
        setFileName(`${TabType}_${dateFormateWithTime(new Date())}`)
        try {
            setIsLoading(true)
            const response = await ApiCall(`/api/Affiliate/getHomeTabDetails`, request);
            if (response.Status === 'SUCCESS') {
                settableData(response);
                setIsLoading(false)
            } else if (response.Status === 'ERROR') {
                settableData([])
                toast.error(response.Message);
                setIsLoading(false)
            } else if (response.Status === "EXPIRED") {
                dispatch(affliateSessionExpire({
                    affliateSessionExpires: true,
                    status: response.Status,
                    message: response.Message
                }))
            }
        } catch (error) {
            toast.error('There was a problem with the server, Please try again later');
        }
        finally {
            setIsLoading(false)
        }
    };

    const handleDownLoadExcel = () => {
        let request = {
            ...filterData,
            Type: 'E',
            PageIndex: '1',
            ...sortConfig,
            pageIndex: `${currentPageNo}`,
            pageSize: pageSize,
            TabType: tabType
        }
        handleDownloadExcel('/api/Affiliate/getHomeTabDetails', fileName, request)
    }

    useEffect(() => {
        document.title = 'Home'
    }, [])

    useEffect(() => {
        getDashboardDataDetails();
    }, [formData.fromDate, formData.toDate]);


    return (
        <>
            <div>
                <div className="home_profile p-2 p-lg-3 rounded overflow-hidden d-flex justify-content-between align-items-center">
                    <div className="profile_photo d-flex gap-3 align-items-center">
                        <div>
                            <div
                                className="border border-2 rounded-circle"
                                style={{
                                    width: "75px",
                                    height: "75px",
                                    overflow: "hidden",
                                    border: "1px solid black",
                                }}
                            >
                                {getUserInfoDetails.profilePhoto ? (
                                    <img
                                        src={`data:image/jpeg;base64,${getUserInfoDetails.profilePhoto}`}
                                        alt="ProfileImage"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                        }}
                                    />
                                ) : (
                                    <img
                                        src="/images/profilepic.png"
                                        alt="ProfileImage"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "contain",
                                        }}
                                    />
                                )}
                            </div>
                        </div>

                        <div>
                            <h2 className='mb-1'>{getUserInfoDetails.firstName}&nbsp;{getUserInfoDetails.lastName}</h2>
                            <p className='m-0 small'>{getUserInfoDetails.emailID}</p>
                        </div>
                    </div>
                    {getUserInfoDetails.refferelCode ? (
                    <div className="text-center">
                        <h6>Your Referral Code</h6>
                        <div className='d-flex align-items-center bg-light p-2 rounded gap-3'>
                            <div className=''>{getUserInfoDetails.refferelCode}</div>
                                <Button sx={{ backgroundColor: yellow[600], color: '#000', lineHeight: 'normal' }} onClick={copyToClipboard} variant="contained">  {buttonText}</Button>
                        </div>
                    </div>
                    ) : (
                    <div className="text-center">
                        <div className='d-flex align-items-center bg-light p-2 rounded gap-3'>
                            <div className='small'>Earn rewards effortlessly! Generate your referral code in the <Link className="btn btn-warning btn-sm" to='/home/Settings'>Settings tab</Link></div>
                        </div>
                    </div>
                    )}


                </div>
                <h4 className='d-flex align-items-center gap-2 mt-4 fw-normal'>Level <div className='badge' style={{ color: `${getUserInfoDetails?.textColor}`, background: `linear-gradient(To right,${getUserInfoDetails?.backgroundColor})` }}>{getUserInfoDetails?.accountLevel}({getUserInfoDetails?.commissionPercentage}%)</div></h4>
                <p className='message'>{getUserInfoDetails?.profileMessage}</p>

                <div className="summary mt-4 mt-md-5">
                    <div className="sum_head d-md-flex justify-content-between align-items-center">
                        <h3 className='mb-md-0'>Summary</h3>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker label="From Date"
                                    value={formData.fromDate}
                                    onChange={(newValue) => setFormData({ ...formData, fromDate: newValue })}
                                />
                                <DatePicker label="To Date"
                                    value={formData.toDate}
                                    onChange={(newValue) => setFormData({ ...formData, toDate: newValue })}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </div>


                    <Row className='mt-4'>
                        <Col className='mb-3' md={3}>

                            <div style={{ cursor: "pointer", backgroundColor: '#ffebb7' }} className="text-center p-1 py-3  p-md-3 rounded "
                                onClick={(e) => { getHomeTabDetails('SOLDSIM', e) }}
                            >
                                <h3>{getSummaryDetails.noofSimSold}</h3>
                                <p className='mb-0'>No of Sim Sold</p>
                            </div>
                        </Col>
                        <Col className='mb-3' md={3}>
                            <div style={{ cursor: "pointer", backgroundColor: '#ffebb7' }} className="text-center p-1 py-3  p-md-3 rounded "
                                onClick={(e) => { getHomeTabDetails('ORDERS', e) }}
                            >
                                <h3>{getSummaryDetails.noOfOrders}</h3>
                                <p className='mb-0'>No of Orders</p>
                            </div>
                        </Col>
                        <Col className='mb-3' md={3}>

                            <div style={{ cursor: "pointer", backgroundColor: '#ffebb7' }} className="text-center p-1 py-3  p-md-3 rounded pointer"
                                onClick={(e) => { getHomeTabDetails('SALESVALUE', e) }}
                            >
                                <h3>{getSummaryDetails.salesValues}</h3>
                                <p className='mb-0'>Sales Values</p>
                            </div>
                        </Col>
                        <Col className='mb-3' md={3}>
                            <div style={{ cursor: "pointer", backgroundColor: '#ffebb7' }} className="text-center p-1 py-3  p-md-3 rounded pointer"
                                onClick={(e) => { getHomeTabDetails('COMMISSIONVALUE', e) }}>
                                <h3>{getSummaryDetails.commisonValue}</h3>
                                <p className='mb-0'>Commison  Value</p>
                            </div>
                        </Col>
                    </Row>
                </div>
                {
                    tableData && tableData?.length !== 0 ?

                        <NewTable
                            PageName={'Home'}
                            handleFilter={() => {
                                getHomeTabDetails()
                                setFilterData({
                                    ...filterData,
                                })
                            }}
                            Filter={false}
                            filterData={filterData}
                            tableData={tableData}
                            setFilterData={setFilterData}
                            currentPageNo={currentPageNo}
                            handleChange1={handleChange1}
                            handleSorting={handleSorting}
                            sortConfig={sortConfig}
                            exportExcelData={true}
                            handleDownLoadExcel={handleDownLoadExcel}
                        />
                        :
                        <>

                        </>
                }
            </div>
        </>
    )
}

export default Hometab