import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import ResetYourPassword from './components/ResetYourPassword';
import Home from './components/Home';
import Pagenotfound from './components/Common/Pagenotfound';
import Homelayout from './components/Homelayout/Homelayout';
import Settings from './components/Tabs/Settings';
import Payments from './components/Tabs/Payments';
import Transactions from './components/Tabs/Transactions';


const App = () => {
    return (
        <Routes>
            {/* Public routes */}
            <Route path="/" element={<Login />} />
            <Route path="/ForgotPassword" element={<ForgotPassword />} />
            <Route path="/ResetPassword" element={<ResetYourPassword />} />

            <Route path="/Home" element={<Homelayout />}>
                <Route index element={<Home />} />
                <Route path="Settings" element={<Settings />} />
                <Route path="Payments" element={<Payments />} />
                <Route path="Transaction" element={<Transactions />} />
            </Route>

            {/*<Route element={<AffliateAuthLayout />}>*/}
            {/*    <Route path="/home" element={<Home />} />*/}
            {/*</Route>*/}
            {/* 404 page */}
            <Route path="*" element={<Pagenotfound />} />
        </Routes>
    )
}

export default App;