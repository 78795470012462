import React from 'react'
import { Link } from 'react-router-dom'

const Pagenotfound = () => {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 text-center">
                            <img src="./assets/images/404.svg" alt="" />
                            <h2 className='mt-4'>Page Not Found</h2>
                            <Link to='/' className="mybtn secondary-bg text-dark mt-3">Back to Home</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Pagenotfound