import { cilWarning } from '@coreui/icons';
import { CIcon } from '@coreui/icons-react';
import { Avatar, Button, Checkbox, FormControlLabel, FormGroup, InputAdornment, TextField, } from '@mui/material';
import Box from '@mui/material/Box';
import { blueGrey, deepPurple, grey, yellow } from '@mui/material/colors';
import { default as React, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from 'react-router-dom';
import { AlertBox, ApiCall, ConfirmAlertBox, deleteCookie, setFocus } from "../../CommonSetting/Config";
import { affliateSessionExpire } from '../../redux/authSlice';
import { useDispatch } from 'react-redux';
import Alert from 'react-bootstrap/Alert';

const Settings = () => {
    const [formdata, setFormdata] = useState({
        name: "",
        phone: "",
        website: "",
        instagram: "",
        twitter: "",
        youtube: "",
        facebook: "",
        profilePhoto: "",
        referralCode: "",
        userCode: "",
        authToken: "",

    })

    const [formdataBank, setFormdataBank] = useState({
        ID:'',
        bankID:'',
        accountType: "",
        accountHolderName: "",
        accountNumber: "",
        bankName: "",
        ifscCode: "",
        swiftCode: "",
        iban: "",
        bankAddress: "",
    })
    console.log(formdataBank,';fhtyjutyyuuyj')
    const [referralCode, setReferralCode] = useState('')
    const [changePassword, setChangePassword] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    })

    const [NotificationSettingData, setNotificationSettingData] = useState({
        saleUpdatedNotification: false,
        paymentProcessedNotification: false,
        newsLetterEmail: false
    })
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            const response = await ApiCall(`/api/Affiliate/UpdateProfile`, formdata);
            if (response.status === 'EXPIRED') {
                dispatch(affliateSessionExpire({
                    affliateSessionExpires: true,
                    status: response.status,
                    message: response.message
                }))
            } else if (response.status === 'SUCCESS') {
                AlertBox('SUCCESS', response.message)
            } else if (response.status === 'ERROR') {
                AlertBox('ERROR', response.message, response.focus)
            }
        } catch (error) {
            AlertBox('ERROR', 'There was a problem with the server, Please try again later')
        }
        finally {
            setLoading(true)
        }
    }

    const handleSubmitBankDetail = async (e) => {
      
        e.preventDefault()
        try {
            setLoading(true)
            const response = await ApiCall(`/api/Affiliate/SaveBankDetails`, formdataBank);
            if (response.status === 'EXPIRED') {
                dispatch(affliateSessionExpire({
                    affliateSessionExpires: true,
                    status: response.status,
                    message: response.message
                }))
            } else if (response.status === 'SUCCESS') {
                AlertBox('SUCCESS', response.message)
                GetUserBasicDetails()
            } else if (response.status === 'ERROR') {
                AlertBox('ERROR', response.message, response.focus)
            }
        } catch (error) {
            AlertBox('ERROR', 'There was a problem with the server, Please try again later')
        }
        finally {
            setLoading(true)
        }
    }


    const SaveReferralCode = async () => {
        ConfirmAlertBox("Warning", "Referral code update is a one-time action. Double-check your entry to ensure it is accurate before proceeding!", async (confirmed) => {
            if (confirmed) {
                try {
                    setLoading(true)
                    const response = await ApiCall(`/api/Affiliate/SaveReferralCode`, { ReferralCode: referralCode });
                    if (response.status === 'EXPIRED') {
                        dispatch(affliateSessionExpire({
                            affliateSessionExpires: true,
                            status: response.status,
                            message: response.message
                        }))
                    } else if (response.status === 'SUCCESS') {
                        AlertBox('SUCCESS', response.message)
                        GetUserBasicDetails();
                    } else if (response.status === 'ERROR') {
                        AlertBox('ERROR', response.message, response.focus)
                    }
                } catch (error) {
                    AlertBox('ERROR', 'There was a problem with the server, Please try again later')
                }
                finally {
                    setLoading(true)
                }
            }
        })
        return;
    }

    const ChangePassword = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            const response = await ApiCall(`/api/Affiliate/ChangePassword`, changePassword);
            if (response.status === 'EXPIRED') {
                dispatch(affliateSessionExpire({
                    affliateSessionExpires: true,
                    status: response.status,
                    message: response.message
                }))
            } else if (response.status === 'SUCCESS') {
                AlertBox('SUCCESS', response.message)
                await deleteCookie('affiliateLoginToken')
                await deleteCookie('affiliateLoginUserCode')
                await deleteCookie('affiliateUserName')
                await deleteCookie('affiliateEmail')
                navigate("/")
            } else if (response.status === 'ERROR') {
                AlertBox('ERROR', response.message, response.focus)
            }
        } catch (error) {
            AlertBox('ERROR', 'There was a problem with the server, Please try again later')
        }
        finally {
            setLoading(true)
        }
    }

    const handleNotificationSetting = async (value) => {
        //    e.preventDefault();
        try {
            setLoading(true)
            const response = await ApiCall(`/api/Affiliate/NotificationSetting`, { ...NotificationSettingData, ...value });
            if (response.status === 'EXPIRED') {
                dispatch(affliateSessionExpire({
                    affliateSessionExpires: true,
                    status: response.status,
                    message: response.message
                }))
            } else if (response.status === 'SUCCESS') {
                AlertBox('SUCCESS', response.message)
            } else if (response.status === 'ERROR') {
                AlertBox('ERROR', response.message, response.focus)
            }
        } catch (error) {
            AlertBox('ERROR', 'There was a problem with the server, Please try again later')
        }
        finally {
            setLoading(true)
        }
    }

    const GetUserBasicDetails = async (e) => {
        try {
            setLoading(true)
            const response = await ApiCall(`/api/Affiliate/GetUserBasicDetails`, { BankDetailsData: { ...formdataBank} });
            if (response.status === 'EXPIRED') {
                dispatch(affliateSessionExpire({
                    affliateSessionExpires: true,
                    status: response.status,
                    message: response.message
                }))
            } else if (response.status === 'SUCCESS') {
                setFormdata(response)
                setFormdataBank({ ...formdataBank, ...response.bankDetailsData, ID: response.bankDetailsData.bankID })
                setNotificationSettingData(response.notificationData)
            } else if (response.status === 'ERROR') {
                AlertBox('ERROR', response.message, response.focus)
            }
        } catch (error) {
            AlertBox('ERROR', 'There was a problem with the server, Please try again later')
        }
        finally {
            setLoading(true)
        }
    }

    const handleCheckboxChange = async (e, field) => {
        await setNotificationSettingData({
            ...NotificationSettingData,
            [field]: e.target.checked,
        });
        await handleNotificationSetting({ [field]: e.target.checked });
    };

    useEffect(() => {
        setFormdata({
            ...formdata,
            name: "",
            phone: "",
            website: "",
            instagram: "",
            twitter: "",
            youtube: "",
            facebook: "",
            profilePhoto: "",
            userCode: "",
            authToken: "",
        })
        GetUserBasicDetails();
        setChangePassword({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        })
    }, []);

    function delay(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    return (
        <>
            <div className="main">
                <Container>
                    <Box sx={{ bgcolor: 'background.paper', }} className="tab_box px-4 py-3" fullwidth>
                        <div className="profile">
                            {
                                formdata !== null ?
                                    <div>
                                        <Row>
                                            <Col md={4}>
                                                <div className="profile_photo d-flex gap-3 align-items-center">
                                                    <div
                                                        className="border border-2 rounded-circle"
                                                        style={{
                                                            width: "100px",
                                                            height: "100px",
                                                            overflow: "hidden",
                                                            border: "1px solid black",
                                                        }}
                                                    >
                                                        {formdata.profilePhoto ? (
                                                            <img
                                                                src={`data:image/jpeg;base64,${formdata.profilePhoto}`}
                                                                alt="ProfileImage"
                                                                style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    objectFit: "cover",
                                                                }}
                                                            />
                                                        ) : (
                                                            <img
                                                                src="/images/profilepic.png"
                                                                alt="ProfileImage"
                                                                style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    objectFit: "contain",
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    <div>
                                                        <h3>{formdata.name ? formdata.name : ""}</h3>
                                                        <p>ID: {formdata.userCode}</p>
                                                    </div>
                                                </div>
                                                <FileUploader
                                                    classes="file-uploader emp-photo mt-4"
                                                    handleChange={(file) => {
                                                        let extArr = (file.name).split(".");
                                                        if (file) {
                                                            const reader = new FileReader();
                                                            reader.readAsDataURL(file);
                                                            reader.onload = () => {
                                                                let logo = (reader.result).split(',')[1];
                                                                setFormdata({
                                                                    ...formdata,
                                                                    profilePhoto: logo
                                                                });
                                                            };
                                                        }
                                                    }}
                                                    name="file"
                                                    types={["JPG", "PNG"]}
                                                />

                                            </Col>
                                            <Col md={8}>

                                                {/*<Form.Control type='file' className='mb-3 mb-md-4'*/}
                                                {/*    handleChange={(file) => {*/}
                                                {/*        let extArr = (file.name).split(".");*/}
                                                {/*        if (file) {*/}
                                                {/*            const reader = new FileReader();*/}
                                                {/*            reader.readAsDataURL(file);*/}
                                                {/*            reader.onload = () => {*/}
                                                {/*                let logo = (reader.result).split(',')[1];*/}
                                                {/*                setFormdata({*/}
                                                {/*                    ...formdata,*/}
                                                {/*                    profilePhoto: logo*/}
                                                {/*                });*/}
                                                {/*            };*/}
                                                {/*        }*/}
                                                {/*    }}*/}
                                                {/*/>*/}

                                                {/* <div className="mb-3 mb-md-4">
                                        <div className="row d-flex align-items-center">
                                            <div className="col-lg-9 col-md-9">
                                                <FileUploader
                                                    classes="file-uploader emp-photo"
                                                    handleChange={(file) => {
                                                        let extArr = (file.name).split(".");
                                                        if (file) {
                                                            const reader = new FileReader();
                                                            reader.readAsDataURL(file);
                                                            reader.onload = () => {
                                                                let logo = (reader.result).split(',')[1];
                                                                setFormdata({
                                                                    ...formdata,
                                                                    profilePhoto: logo
                                                                });
                                                            };
                                                        }
                                                    }}
                                                    name="file"
                                                    types={["JPG", "PNG"]}
                                                />
                                            </div>


                                            <div className="col-lg-3 col-md-3 d-flex justify-content-start ml-5">
                                                <div
                                                    className="border border-2 rounded-circle"
                                                    style={{
                                                        width: "100px",
                                                        height: "100px",
                                                        overflow: "hidden",
                                                        border: "1px solid black",
                                                    }}
                                                >
                                                    {formdata.profilePhoto ? (
                                                        <img
                                                            src={`data:image/jpeg;base64,${formdata.profilePhoto}`}
                                                            alt="ProfileImage"
                                                            style={{
                                                                width: "100%",
                                                                height: "100%",
                                                                objectFit: "cover",
                                                            }}
                                                        />
                                                    ) : (
                                                        <img
                                                            src="/images/profilepic.png"
                                                            alt="ProfileImage"
                                                            style={{
                                                                width: "100%",
                                                                height: "100%",
                                                                objectFit: "contain",
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                                <div className="d-md-flex justify-content-between alig-item-center mb-3 mb-md-4 gap-3 mt-3">
                                                    <TextField
                                                        label="Your Name*"
                                                        id="txtName"
                                                        size="small"
                                                        fullWidth
                                                        className='mb-3 mb-md-0'
                                                        onChange={(e) => setFormdata({ ...formdata, name: e.target.value })}
                                                        value={formdata.name}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                e.preventDefault()
                                                                setFocus('txtPhone')
                                                            };
                                                        }}
                                                    />
                                                    <TextField
                                                        type='number'
                                                        label="Your Phone Number*"
                                                        id="txtPhone"
                                                        size="small"
                                                        fullWidth
                                                        onChange={(e) => setFormdata({ ...formdata, phone: e.target.value })}
                                                        value={formdata.phone}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                e.preventDefault()
                                                                setFocus('txtWebsite')
                                                            };
                                                        }}
                                                    />
                                                </div>
                                                <TextField
                                                    label="Website*"
                                                    id="txtWebsite"
                                                    size="small"
                                                    fullWidth
                                                    className='mb-3 mb-md-4'
                                                    slotProps={{
                                                        input: {
                                                            startAdornment: <InputAdornment position='start'>https://</InputAdornment>
                                                        }
                                                    }}
                                                    onChange={(e) => setFormdata({ ...formdata, website: e.target.value })}
                                                    value={formdata.website}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            e.preventDefault()
                                                            setFocus('txtInstagram')
                                                        };
                                                    }}
                                                />
                                                <div className="d-md-flex justify-content-between alig-item-center mb-3 mb-md-4 gap-3">
                                                    <TextField
                                                        label="Instagram*"
                                                        id="txtInstagram"
                                                        size="small"
                                                        fullWidth
                                                        className='mb-3 mb-md-0'
                                                        slotProps={{
                                                            input: {
                                                                startAdornment: <InputAdornment position='start'>@</InputAdornment>
                                                            }
                                                        }}
                                                        onChange={(e) => setFormdata({ ...formdata, instagram: e.target.value })}
                                                        value={formdata.instagram}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                e.preventDefault()
                                                                setFocus('txtTwitter')
                                                            };
                                                        }}
                                                    />
                                                    <TextField
                                                        label="Twitter*"
                                                        id="txtTwitter"
                                                        size="small"
                                                        fullWidth

                                                        slotProps={{
                                                            input: {
                                                                startAdornment: <InputAdornment position='start'>@</InputAdornment>
                                                            }
                                                        }}
                                                        onChange={(e) => setFormdata({ ...formdata, twitter: e.target.value })}
                                                        value={formdata.twitter}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                e.preventDefault()
                                                                setFocus('txtYoutube')
                                                            };
                                                        }}
                                                    />
                                                </div>
                                                <TextField
                                                    label="Youtube*"
                                                    id="txtYoutube"
                                                    size="small"
                                                    fullWidth
                                                    className='mb-3 mb-md-4'
                                                    slotProps={{
                                                        input: {
                                                            startAdornment: <InputAdornment position='start'>youtube.com/channel/</InputAdornment>
                                                        }
                                                    }}
                                                    onChange={(e) => setFormdata({ ...formdata, youtube: e.target.value })}
                                                    value={formdata.youtube}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            e.preventDefault()
                                                            setFocus('txtFacebook')
                                                        };
                                                    }}
                                                />
                                                <TextField
                                                    label="Facebook*"
                                                    id="txtFacebook"
                                                    size="small"
                                                    fullWidth
                                                    className='mb-3 mb-md-4'
                                                    slotProps={{
                                                        input: {
                                                            startAdornment: <InputAdornment position='start'>fb.com/</InputAdornment>
                                                        }
                                                    }}
                                                    onChange={(e) => setFormdata({ ...formdata, facebook: e.target.value })}
                                                    value={formdata.facebook}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            handleSubmit(e)
                                                        };
                                                    }}
                                                />
                                                <Button onClick={(e) => handleSubmit(e)} sx={{ backgroundColor: yellow[600], color: '#000' }} variant="contained">Submit</Button>
                                            </Col>
                                        </Row>

                                        <Row className='mt-4'>
                                            <Col md={12}>
                                                <div className="p-3 rounded" style={{ backgroundColor: blueGrey[50] }}>
                                                    <h3>Referral Code</h3>
                                                    <Alert variant="danger" className='d-inline-flex gap-2 py-1'><CIcon icon={cilWarning} className='mt-1' style={{ height: '17px', width: '17px' }} /> Important Notice : Referral code update is a one-time action. Double-check your entry to ensure it is accurate before proceeding!</Alert>
                                                    {/*<p className='mb-0 text-white bg-danger p-1 rounded d-inline-flex align-items-center gap-2 ps-2'><div><CIcon icon={cilWarning} style={{ height: '17px', width: '17px' }} /></div> Important Notice : Referral code update is a one-time action. Double-check your entry to ensure it is accurate before proceeding!</p>*/}
                                                    {/*<p className='text-black-50'><small>eg. https://www.snitch.co.in/?ref=arunsingh</small></p>*/}

                                                    {/*<Button onClick={(e) => SaveReferralCode(e)} sx={{ backgroundColor: yellow[600], color: '#000' }} variant="contained">Submit</Button>*/}
                                                    <div className="d-flex justify-content-between align-items-center m-2" >

                                                        <Col md={4}>
                                                            <Row>
                                                                <Col md={10}> <TextField
                                                                    className="mt-2 d-flex col-md-5"
                                                                    label="Referral Code"
                                                                    id="txtReferralCode"
                                                                    size="small"
                                                                    fullWidth
                                                                    disabled={formdata.referralCode !== '' ? true : false}
                                                                    defaultValue='arunsingh'
                                                                    onChange={(e) => setReferralCode(e.target.value)}
                                                                    value={formdata.referralCode !== '' ? formdata.referralCode : referralCode}
                                                                    maxLength='15'
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            SaveReferralCode()
                                                                        };
                                                                    }}
                                                                />
                                                                </Col>
                                                                <Col md={2}
                                                                    style={{ display: (formdata.referralCode !== '') ? 'none' : 'block' }}
                                                                >
                                                                    <Button onClick={(e) => SaveReferralCode(e)} sx={{
                                                                        backgroundColor: yellow[600], color: '#000'
                                                                    }}
                                                                        className="m-2 d-flex" variant="contained">Submit</Button>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </div>
                                                </div>
                                            </Col>
                                            {/*<Col md={6}>*/}
                                            {/*    <div className="p-3 rounded h-100" style={{ backgroundColor: blueGrey[50] }}>*/}
                                            {/*        <h3>Payment Settings</h3>*/}
                                            {/*        <p className='mb-0'>How would you like to get paid ?</p>*/}
                                            {/*        <p className='text-danger d-flex justify-content-between'><small>Payment Term: Net 30</small> <small>Minimum Payout: ₹5,000</small></p>*/}
                                            {/*        <div className="d-flex justify-content-between align-items-center">*/}
                                            {/*            <p className='mb-0'><b>Store Discount Coupon</b></p>*/}
                                            {/*            <Button sx={{ backgroundColor: yellow[600], color: '#000' }} variant="contained">Change</Button>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</Col>*/}
                                        </Row>
                                    </div>
                                    : <></>
                            }

                            <Row className='mt-4'>
                                <h3>Bank Details</h3>
                                <Col md={12}>
                                    <div className="p-3 rounded h-100" style={{ backgroundColor: grey[100] }}>
                                       
                                        <div className="d-md-flex justify-content-between alig-item-center gap-2 mb-3">
                                            
                                            <TextField
                                                label="Account Type"
                                                id="txtAccountType"
                                                size="small"
                                                fullWidth
                                                className='mb-3 mb-md-0'
                                                onChange={(e) => setFormdataBank({ ...formdataBank, accountType: e.target.value })}
                                                value={formdataBank.accountType}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault()
                                                        setFocus('txtAccountHolderName')
                                                    };
                                                }}
                                                />
                                           
                                            <TextField
                                                label="Account Holder Name*"
                                                id="txtAccountHolderName"
                                                size="small"
                                                fullWidth
                                                className='mb-3 mb-md-0'
                                                onChange={(e) => setFormdataBank({ ...formdataBank, accountHolderName: e.target.value })}
                                                value={formdataBank.accountHolderName}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault()
                                                        setFocus('txtAccountNumber')
                                                    };
                                                }}
                                            />
                                            <TextField
                                                label="Account Number*"
                                                id="txtAccountNumber"
                                                size="small"
                                                fullWidth
                                                className='mb-3 mb-md-0'
                                                onChange={(e) => setFormdataBank({ ...formdataBank, accountNumber: e.target.value })}
                                                value={formdataBank.accountNumber}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault()
                                                        setFocus('txtBankName')
                                                    };
                                                }}
                                            />
                                        </div>
                                        <div className="d-md-flex justify-content-between alig-item-center gap-2 mb-3">
                                            <TextField
                                                label="Bank Name*"
                                                id="txtBankName"
                                                size="small"
                                                fullWidth
                                                className='mb-3 mb-md-0'
                                                onChange={(e) => setFormdataBank({ ...formdataBank, bankName: e.target.value })}
                                                value={formdataBank.bankName}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault()
                                                        setFocus('txtIFSCCode')
                                                    };
                                                }}
                                            />
                                            <TextField
                                                label="IFSC Code"
                                                id="txtIFSCCode"
                                                size="small"
                                                fullWidth
                                                className='mb-3 mb-md-0'
                                                onChange={(e) => setFormdataBank({ ...formdataBank, ifscCode: e.target.value })}
                                                value={formdataBank.ifscCode}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault()
                                                        setFocus('txtSWIFTCode')
                                                    };
                                                }}
                                            />
                                            <TextField
                                                label="SWIFT Code"
                                                id="txtSWIFTCode"
                                                size="small"
                                                fullWidth
                                                className='mb-3 mb-md-0'
                                                onChange={(e) => setFormdataBank({ ...formdataBank, swiftCode: e.target.value })}
                                                value={formdataBank.swiftCode}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault()
                                                        setFocus('txtIBAN')
                                                    };
                                                }}
                                            />
                                        </div>
                                        <div className="d-md-flex justify-content-between alig-item-center gap-2 mb-3">
                                            <TextField
                                                label="IBAN (International Bank Account Number)"
                                                id="txtIBAN"
                                                size="small"
                                                fullWidth
                                                className='mb-3 mb-md-0'
                                                onChange={(e) => setFormdataBank({ ...formdataBank, iban: e.target.value })}
                                                value={formdataBank.iban}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault()
                                                        setFocus('txtBankAddress')
                                                    };
                                                }}
                                            />
                                            <TextField
                                                label="Bank Address*"
                                                id="txtBankAddress"
                                                size="small"
                                                fullWidth
                                                className='mb-3 mb-md-0'
                                                onChange={(e) => setFormdataBank({ ...formdataBank, bankAddress: e.target.value })}
                                                value={formdataBank.bankAddress}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleSubmitBankDetail()
                                                    };
                                                }}
                                            />
                                        </div>
                                        <Button onClick={(e) => handleSubmitBankDetail(e)} sx={{ backgroundColor: yellow[600], color: '#000' }} variant="contained">Submit</Button>
                                    </div>
                                </Col>
                                
                            </Row>
















                            <Row className='mt-4'>
                                <h3>Settings</h3>
                                <Col md={6}>
                                    <div className="p-3 rounded h-100" style={{ backgroundColor: grey[100] }}>
                                        <h6>Change Password</h6>
                                        <div className="d-md-flex justify-content-between alig-item-center gap-2 mb-3">
                                            <TextField type='password' className='mb-3 mb-md-0' label="Current password" id="txtOldPassword"
                                                size="small" fullWidth
                                                onChange={(e) => setChangePassword({ ...changePassword, oldPassword: e.target.value })}
                                                value={changePassword.oldPassword}
                                                maxLength='15'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault()
                                                        setFocus('txtNewPassword')
                                                    };
                                                }}
                                            />
                                            <TextField type='password' className='mb-3 mb-md-0' label="New Password" id="txtNewPassword" size="small" fullWidth
                                                onChange={(e) => setChangePassword({ ...changePassword, newPassword: e.target.value })}
                                                value={changePassword.newPassword}
                                                maxLength='15'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault()
                                                        setFocus('txtConfirmPassword')
                                                    };
                                                }}
                                            />
                                            <TextField type='password' className='mb-3 mb-md-0' label="Confirm password" id="txtConfirmPassword" size="small" fullWidth
                                                onChange={(e) => setChangePassword({ ...changePassword, confirmPassword: e.target.value })}
                                                value={changePassword.confirmPassword}
                                                maxLength='15'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        ChangePassword()
                                                    };
                                                }}
                                            />
                                        </div>
                                        <Button onClick={(e) => ChangePassword(e)} sx={{ backgroundColor: yellow[600], color: '#000' }} variant="contained">Submit</Button>
                                    </div>
                                </Col>
                                {NotificationSettingData !== null ?
                                    <Col md={6}>
                                        <div className="p-3 rounded h-100" style={{ backgroundColor: grey[100] }}>
                                            <h6>Notification Settings</h6>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={<Checkbox checked={NotificationSettingData.saleUpdatedNotification} />}
                                                    label="Sale updated notification"
                                                    onChange={(e) => handleCheckboxChange(e, 'saleUpdatedNotification')}
                                                />
                                                <FormControlLabel control={<Checkbox
                                                    checked={NotificationSettingData.paymentProcessedNotification} />}
                                                    label="Payment processed notification"

                                                    onChange={(e) => handleCheckboxChange(e, 'paymentProcessedNotification')}
                                                />
                                                <FormControlLabel
                                                    control={<Checkbox checked={NotificationSettingData.newsLetterEmail} />}
                                                    label="Monthly Report"
                                                    onChange={(e) => handleCheckboxChange(e, 'newsLetterEmail')}
                                                />
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    : <></>}
                            </Row>
                        </div>
                    </Box>
                </Container>
            </div>
        </>
    )
}

export default Settings