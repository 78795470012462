import React, { useState, useEffect } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AlertBox, ApiCall, setFocus } from "../CommonSetting/Config";

const ResetYourPassword = () => {
    const [resetPassdata, setResetPassdata] = useState({
        newPassword: "",
        confirmPassword: "",
        userCode: "",
        authToken: "",
    })
    const navigate = useNavigate()
    const location = useLocation();

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        await delay(500);
        if (resetPassdata.userCode !== "admin" || resetPassdata.newPassword !== "admin") {
            setShow(true);
        }
        setLoading(false);
    };

    const handleResetPassword = async (e) => {
        e.preventDefault()

        try {
            const response = await ApiCall(`/api/Affiliate/UpdateForgotPassword`, resetPassdata);
            if (response.status === 'SUCCESS') {
                AlertBox('SUCCESS', response.message)
                navigate("/")
            } else if (response.status === 'ERROR') {
                AlertBox('ERROR', response.message,response.focus)
            }
        } catch (error) {
            AlertBox('ERROR', 'There was a problem with the server, Please try again later')
        }
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const userCode = searchParams.get('UserCode');
        const AuthToken = searchParams.get('AuthToken');
        setResetPassdata({
            ...resetPassdata,
            authToken: AuthToken,
            userCode: userCode,
        })
    }, [location.search]);

    function delay(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    return (
        <div
            className="sign-in__wrapper"
        >
            {/* Overlay */}
            <div className="sign-in__backdrop"></div>
            {/* Form */}
            <Form className="shadow p-4 bg-white rounded" onSubmit={handleSubmit}>
                {/* Header */}
                <div className="h4 mb-2 text-center">Reset Your Password</div>
                {/* ALert */}
                {show ? (
                    <Alert
                        className="mb-2"
                        variant="danger"
                        onClose={() => setShow(false)}
                        dismissible
                    >
                        Incorrect username or password.
                    </Alert>
                ) : (
                    <div />
                )}

                {/*<Form.Group className="mb-2" controlId="oldpassword">*/}
                {/*  <Form.Label>Old Password</Form.Label>*/}
                {/*  <Form.Control*/}
                {/*    type="password"*/}
                {/*    placeholder="Password"*/}
                {/*    onChange={(e) => setInputPassword(e.target.value)}*/}
                {/*    required*/}
                {/*  />*/}
                {/*</Form.Group>*/}
                <Form.Group className="mb-2" controlId="newpassword">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="New Password"
                        onChange={(e) => setResetPassdata({ ...resetPassdata, newPassword: e.target.value })}
                        required

                        className="form-control"
                        id='txtNewPassword'
                        name='newPassword'
                        value={resetPassdata.newPassword}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault()
                                setFocus('txtConfirmPassword')
                            };
                        }}
                    />
                </Form.Group>
                <Form.Group className="mb-2" controlId="confirmpassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Confirm Password"
                        onChange={(e) => setResetPassdata({ ...resetPassdata, confirmPassword: e.target.value })}
                        required

                        className="form-control"
                        id='txtConfirmPassword'
                        name='confirmPassword'
                        value={resetPassdata.confirmPassword}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleResetPassword(e)
                            };
                        }}
                    />
                </Form.Group>
                <Form.Group className="mb-2" controlId="checkbox">
                    <Form.Check type="checkbox" label="Remember me" />
                </Form.Group>
                {!loading ? (
                    <Link className="w-100 btn btn-primary" variant="primary"
                        onClick={(e) => handleResetPassword(e)} 
                        type="submit">
                        Submit Your Password
                    </Link>
                    
                ) : (
                    <Button className="w-100" variant="primary" type="submit" disabled>
                        Logging In...
                    </Button>
                )}
            </Form>
        </div>
    );
};

export default ResetYourPassword;
