import { Outlet, useNavigate, Navigate } from 'react-router-dom';
import NavMenu from '../NavMenu';
import Footer from '../Footer';
import { AlertBox, deleteCookie, getCookie } from '../../CommonSetting/Config';
import { useDispatch, useSelector } from 'react-redux';
import { affliateSessionExpire } from '../../redux/authSlice';


const Homelayout = () => {
    const affiliateLoginToken = getCookie('affiliateLoginToken')
    const affiliateLoginUserCode = getCookie('affiliateLoginUserCode')
    const affiliateUserName = getCookie('affiliateUserName')


    const navigate = useNavigate()
    const { affliateSessionExpires, message } = useSelector(state => state.authData.affliateSessionDetails)
    const dispatch = useDispatch()

    const removeCookies = async () => {
        await dispatch(affliateSessionExpire({
            adminSessionExpires: false,
            status: '',
            mesaage: ''
        }))

        await deleteCookie('affiliateLoginToken')
        await deleteCookie('affiliateLoginUserCode')
        await deleteCookie('affiliateUserName')
        await deleteCookie('affiliateEmail')
        navigate("/")
     
    }

    if (affliateSessionExpires) {
        AlertBox("Expired", message, '', removeCookies)
    }
    return (
        affiliateLoginToken && affiliateLoginUserCode && affiliateUserName ?
            <>
                <div className='container d-flex flex-column' style={{minHeight: '100vh'}}>
                    <NavMenu />
                    <div className='d-flex flex-column justify-content-between' style={{ minHeight: '80vh' }}>
                        <Outlet />
                        <Footer />
                    </div>
                </div>
            </>
            :
            <Navigate to="/" replace={true} />
    )
}

export default Homelayout;