import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container } from 'react-bootstrap';
import Hometab from './Tabs/Hometab';
import Footer from './Footer';
import { MarketingTools } from './Tabs/MarketingTools';
import Transactions from './Tabs/Transactions';
import Payments from './Tabs/Payments';
import Settings from './Tabs/Settings';
import { deleteCookie } from '../CommonSetting/Config';
import { useNavigate, Link } from 'react-router-dom';
interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function Home() {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate();
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <div  className="main">
                <Container>
                    <Box sx={{ bgcolor: 'background.paper', }} className="tab_box px-4 py-3" fullwidth>
                        <Hometab />
                    </Box>
                </Container>
            </div>
           

            <div style={{ display:"none" }} className="main">
                <Container>
                    {/*<div className="error_msg">Your account is still under verification. Please allow up to 24-48 for the account to get verified. Kindly update your profile in the meantime</div>*/}
                    <Box sx={{ bgcolor: 'background.paper' }} className="tab_box" fullwidth>
                       
                        <AppBar position="static">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >
                                <Tab className='text-nowrap' sx={{ minWidth: '155px' }} label="Home" {...a11yProps(0)} />
                                <Tab style={{display:'none'}} className='text-nowrap' sx={{ minWidth: '155px' }} label="Marketing Tools" {...a11yProps(1)} />
                                <Tab className='text-nowrap' sx={{ minWidth: '155px' }} label="Transactions" {...a11yProps(2)} />
                                <Tab className='text-nowrap' sx={{ minWidth: '155px' }} label="Payments" {...a11yProps(3)} />
                                <Tab className='text-nowrap' sx={{ minWidth: '155px' }} label="Settings" {...a11yProps(4)} />
                                <Tab onClick={async () => {
                                    await deleteCookie('affiliateLoginToken')
                                    await deleteCookie('affiliateLoginUserCode')
                                    await deleteCookie('affiliateUserName')
                                    await deleteCookie('affiliateEmail')
                                    navigate("/")
                                }} className='text-nowrap' sx={{ minWidth: '155px' }} label="Logout" {...a11yProps(5)} />

                            </Tabs>
                        </AppBar>
                        <TabPanel sx={{ backgroundColor: '#fff' }} value={value} index={0} dir={theme.direction}>
                            <Hometab />
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>

                            <MarketingTools />
                        </TabPanel>
                        <TabPanel value={value} index={2} dir={theme.direction}>
                            <Transactions />
                        </TabPanel>
                        <TabPanel value={value} index={3} dir={theme.direction}>
                            <Payments />
                        </TabPanel>
                        <TabPanel value={value} index={4} dir={theme.direction}>
                            <Settings />
                        </TabPanel>
                    </Box>
                </Container>
            </div>

        </>
    );
}