import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { AlertBox, ApiCall, getCookie, setCookie } from "../CommonSetting/Config";
import { Navigate, Link, useNavigate } from 'react-router-dom';


const Login = () => {


    const [AffiliateLoginDetails, setAffiliateLoginDetails] = useState({
        Email: '',
        EmailPassword: '',
        Token:'TBHWG-SIPL-GVTF7-SIMFLY-JQMKV'
    })

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const affiliateLoginToken = getCookie('affiliateLoginToken')
    const affiliateLoginUserCode = getCookie('affiliateLoginUserCode')
    const affiliateUserName = getCookie('affiliateUserName')
    const affiliateEmail = getCookie('affiliateEmail')

    const AuthLogin = async (event) => {
        event.preventDefault();
        try {
            const response = await ApiCall(`/api/Affiliate/Login`, AffiliateLoginDetails);
            if (response.status === 'SUCCESS') {
                await setCookie('affiliateLoginToken', response.loginToken, 1);
                await setCookie('affiliateLoginUserCode', response.loginUserCode, 1);
                await setCookie('affiliateUserName', response.userName, 1);
                await setCookie('affiliateEmail', response.emailID, 1);
                await navigate("/home/")
            } else if (response.status === 'ERROR') {
                AlertBox('ERROR', response.message)
            }
        } catch (error) {
            AlertBox('ERROR', 'There was a problem with the server, Please try again later')
        }
    };


    const handlePassword = () => { };

    function delay(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    return (
        affiliateLoginToken && affiliateLoginUserCode && affiliateUserName ?
            <Navigate to="/home/" replace={true} />
            :
        <div
            className="sign-in__wrapper"
        >
            {/* Overlay */}
            <div className="sign-in__backdrop"></div>
            {/* Form */}
            <Form className="shadow p-4 bg-white rounded">
                {/* Header */}
                <div className="h4 mb-2 text-center">Log In</div>
                {/* ALert */}
                {show ? (
                    <Alert
                        className="mb-2"
                        variant="danger"
                        onClose={() => setShow(false)}
                        dismissible
                    >
                        Incorrect username or password.
                    </Alert>
                ) : (
                    <div />
                )}
                <Form.Group className="mb-2" controlId="username">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                        type="text"
                        value={AffiliateLoginDetails.Email}
                        placeholder="Email address"
                        onChange={(e) => setAffiliateLoginDetails({ ...AffiliateLoginDetails, Email:e.target.value})}
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-2" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        value={AffiliateLoginDetails.EmailPassword}
                        placeholder="Password"
                        onChange={(e) => setAffiliateLoginDetails({ ...AffiliateLoginDetails, EmailPassword: e.target.value })}
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-2" controlId="checkbox">
                    <Form.Check type="checkbox" label="Remember me" />
                </Form.Group>
                {!loading ? (
                    <Button onClick={(e) => AuthLogin(e)} className="w-100 btn btn-primary" variant="primary" type="submit">
                        Log In
                    </Button>
                ) : (
                    <Button className="w-100" variant="primary" type="submit" disabled>
                        Logging In...
                    </Button>
                )}
                <div className="d-grid justify-content-end">
                    <Link
                        className="text-muted px-0 mt-3"
                        to='/forgotpassword'
                    >
                        Forgot password?
                    </Link>
                </div>
            </Form>
                <p className="mt-4">Don't Have account? <Link to="https://simfly.biz/simfly_new-partner-platform" target="_blank" >Create Account</Link></p>
        </div>
    );
};

export default Login;
