import { createSlice } from "@reduxjs/toolkit";
import { deleteCookie } from "../CommonSetting/Config";

const initialState = {
    affliateSessionDetails: {
        affliateSessionExpires: false,
        status: '',
        message: ''
    }
};


export const authSlice = createSlice({
    name: "affliate",
    initialState,
    reducers: {
        setUser: (state, action) => {
            console.log('setUsersetUser')
        },
        logOutUser: (state) => {
            console.log('logOutUserlogOutUser')
        },
        affliateSessionExpire: (state, action) => {
             deleteCookie('affiliateLoginToken')
             deleteCookie('affiliateLoginUserCode')
             deleteCookie('affiliateUserName')
             deleteCookie('affiliateEmail')
            state.affliateSessionDetails.affliateSessionExpires = action.payload.affliateSessionExpires
            state.affliateSessionDetails.status = action.payload.status
            state.affliateSessionDetails.message = action.payload.message    
        }
    }
});

export const { setUser, logOutUser, affliateSessionExpire } = authSlice.actions;

export default authSlice.reducer;
