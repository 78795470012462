import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState, useEffect } from "react";
import { Pagination, Stack } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#4b2b7d",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export const CommonTable = ({ tableData }) => {
    const [MainData, setMainData] = useState(
        tableData?.Data ? tableData?.Data : []
    );

    useEffect(() => {
        setMainData(tableData?.Data);
    }, [tableData]);

    const finalHeadersKey = tableData?.HeadersKey || [];
    const finalHeadersValue = tableData?.HeadersValue || [];

    return tableData ? (
        <>
            <TableContainer component={Paper} className="mt-3">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    {/* Table Header */}
                    <TableHead>
                        <TableRow>
                            {finalHeadersKey.map((header, index) => (
                                <StyledTableCell key={index}>{header}</StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    {/* Table Body */}
                    <TableBody>
                        {MainData?.map((row, rowIndex) => (
                            <StyledTableRow key={rowIndex}>
                                {finalHeadersValue.map((key, colIndex) => (
                                    <StyledTableCell key={colIndex}>
                                        {row[key] || ""}
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="d-flex justify-content-center mt-3">
                <Stack spacing={2}>
                    <Pagination count={10} shape="rounded" />
                </Stack>
            </div>
        </>
    ) : (
        <></>
    );
};
