import { DraftsRounded } from '@mui/icons-material';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { AlertBox, ApiCall, dateFormateWithTime, handleDownloadExcel } from '../../CommonSetting/Config';
import Loader from '../Common/Loader';
import { CommonTable } from './CommonTable';
import { useDispatch } from 'react-redux';
import { affliateSessionExpire } from '../../redux/authSlice';
import { NewTable } from '../Common/NewTable';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const Transactions = () => {
    const [loading, setLoading] = useState(true)
    const [tableData, setTableData] = useState([]);

    const dispatch = useDispatch()

    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ sortColumn: null, sortOrder: null });
    const [pageSize, setPageSize] = useState("10");
    const [filterData, setFilterData] = useState({
        type: "S",
        searchText: "",
        searchFieldValue: "",
        startValue: "",
        AdvanceFilter: []
    })
    const [fileName, setFileName] = useState()

    const [yearDropdown, setYearDropdown] = useState([]);
    const [formData, setFormData] = useState({
        year: ''
    })

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    const handleChange1 = (event, pagenumber) => {
        setCurrentPageNo(pagenumber)
    }

    const handleDownLoadExcel = () => {
        const currentYear = new Date().getFullYear();
        const year = formData.year ? String(formData.year) : String(currentYear);
        let request = {
            AdvanceFilter: [{
                "SearchingValue": filterData?.SearchFieldValue,
                "ComparisonType": filterData?.StartValue,
                "SearchingText": filterData?.SearchText,
            }],
            Type: 'E',
            PageIndex: '1',
            ...sortConfig,
            pageSize: pageSize,
            year: year
        }
        handleDownloadExcel('/api/Affiliate/GetTransactions', fileName, request)
    }

    const GetTransactions = async () => {
        const currentYear = new Date().getFullYear(); 
        const year = formData.year ? String(formData.year) : String(currentYear);
        let request = {
            ...filterData,
            ...sortConfig,
            pageIndex: `${currentPageNo}`,
            pageSize: pageSize,
            year: year
        }
        try {
            const response = await ApiCall(`/api/Affiliate/GetTransactions`, request);
            if (response.Status === "SUCCESS") {
                 setTableData(response)
            } else if (response.Status === "ERROR") {
                setTableData([])
                //AlertBox(response.Status, response.Message)
            } else if (response.Status === "EXPIRED") {
                dispatch(affliateSessionExpire({
                    affliateSessionExpires: true,
                    status: response.Status,
                    message: response.Message
                }))
            }
        } catch (error) {
            AlertBox('ERROR', 'There was a problem with the server, Please try again later')
        }
        finally {
            setLoading(false)
        }
    }

    const yearDropdownList = async () => {
        let request = {
            "Type": "GetYear",
        }
        try {
            setLoading(true)
            let res = await ApiCall('/api/Affiliate/GetMasterData', { ...request })
            if (res.status === 'SUCCESS') {
                setYearDropdown(res.dataList);
                setFormData({ ...formData, year: res?.dataList[0]?.code });
            } else if (res.status === 'EXPIRED') {
                dispatch(affliateSessionExpire({
                    affliateSessionExpires: true,
                    status: res.status,
                    message: res.message
                }))
            }
            else if (res.status === 'ERROR') {
                setYearDropdown([])
            }
        }
        catch (error) {
            AlertBox('ERROR', 'Failed to show the data. Please try again.')
        }
        finally {
            setLoading(false)
        }
    }


    useEffect(() => {
        document.title = 'Transaction'
        yearDropdownList();
        setFileName(`Transaction ${dateFormateWithTime(new Date())}`)
    }, [])

    useEffect(() => {
        GetTransactions()
    }, [currentPageNo, sortConfig, pageSize, formData.year])

    return (
        <>
            <div className="main">
                <Container>
                   
                    <Box sx={{ bgcolor: 'background.paper', }} className="tab_box px-4 py-3" fullwidth>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div className='d-flex gap-3'>
                                <h4 className='mb-0'>Details</h4>
                                <FormControl fullWidth className='col-12' size='small'>
                                    <InputLabel id="demo-simple-select-label">Year</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={formData.year}
                                        label="Year"
                                        onChange={(e) => setFormData({ ...formData, year: e.target.value })}
                                    >
                                        {
                                            yearDropdown?.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item.code}>{item.name}</MenuItem>
                                                )
                                            })
                                        }
                                       
                                    </Select>
                                </FormControl>
                            </div>

                        </div>
                        {
                            tableData && tableData?.length !== 0 ?
                                <NewTable
                                    PageName={'Transaction'}
                                    handleFilter={() => {
                                        GetTransactions()
                                        setFilterData({
                                            ...filterData,
                                        })
                                    }}
                                    Filter={false}
                                    filterData={filterData}
                                    tableData={tableData}
                                    setFilterData={setFilterData}
                                    currentPageNo={currentPageNo}
                                    handleChange1={handleChange1}
                                    handleSorting={handleSorting}
                                    sortConfig={sortConfig}
                                    exportExcelData={true }
                                    handleDownLoadExcel={handleDownLoadExcel}
                                />
                                :
                                <div className="p-3 bg-light w-100 text-center">
                                    <DraftsRounded sx={{ fontSize: '40px' }} />
                                    <p>No Record Found</p>
                                </div>
                        }
                    </Box>
                </Container>
            </div>
            {loading && <Loader />}
        </>
    )
}

export default Transactions