import axios from "axios";
import Swal from 'sweetalert2';
import { useEffect } from 'react';
import { useState } from 'react';
import { Cookies } from 'react-cookie';
import ExcelJS from 'exceljs';
import DatePicker from 'react-datepicker';

const cookies = new Cookies();
const virtualPath = '';


let baseUrl;
if (window.location.hostname.indexOf("react.") >= 0) {
    baseUrl = `${window.location.protocol}//${window.location.host}/${window.location.pathname.split('/')[1]}`;
} else {
    baseUrl = `${window.location.protocol}//${window.location.host}`;
}
export const API_SERVER = baseUrl;


export const ApiCall = (url, bodyData) => {

    let payloadData = {
        ...bodyData,
        Token: "TBHWG-SIPL-GVTF7-SIMFLY-JQMKV",
        LoginUserCode: getCookie('affiliateLoginUserCode'),
        LoginToken: getCookie('affiliateLoginToken'),
        EmailID: getCookie('affiliateEmail'),
    }
    const abortController = new AbortController();
    let data = fetch(`${API_SERVER + url}`, {
        method: "POST",
        body: JSON.stringify(payloadData),
        headers: {
            "Content-Type": "application/json"
        },
        signal: abortController.signal
    }).then((res) => res.json())
        .then((res) => {

            return res;
        })
        .catch((err) => {
            return err
        }).finally(() => {
            abortController.abort();
        });
    return data
};


const post = (url, payload, onDone) => {
    axios({
        method: "post",
        url: url,
        data: payload,
        headers: {
            "Content-Type": "application/json" // Ensure payload is treated as JSON
        }
    }).then(res => {
        onDone(res.data);

    }).catch(error => {
        if (error.response) {
            console.log(error.response)
        }
    });
};

export { post };

export const dateFormateWithTime = (value) => {
    let dateArr = value?.toString()?.split(' ');
    dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]} ${dateArr[4]}`
    return dateArr;

}

export const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
};

export const deleteCookie = (name) => {
    setCookie(name, "", -1);
};


export const SetSession = (type, key, value) => {
    let tempKey = (virtualPath).replace("/", "").toLowerCase() + "_" + key;
    if (type.toLowerCase() === ('localStorage').toLowerCase()) {
        window.localStorage.setItem(tempKey, value);
    } else if (type.toLowerCase() === ('cookies').toLowerCase()) {
        cookies.set(tempKey, value, { path: "/" });
    } else if (type.toLowerCase() === ('sessionStorage').toLowerCase()) {
        sessionStorage.setItem(tempKey, value);
    }
}

export const GetSession = (type, key) => {
    let tempKey = (virtualPath).replace("/", "").toLowerCase() + "_" + key;
    if (type.toLowerCase() === ('localStorage').toLowerCase()) {
        let data = window.localStorage.getItem(tempKey);
        return data
    } else if (type.toLowerCase() === ('cookies').toLowerCase()) {
        let data = cookies.get(tempKey);
        return data
    } else if (type.toLowerCase() === ('sessionStorage').toLowerCase()) {
        let data = sessionStorage.getItem(tempKey);
        return data
    }
}


export const AlertBox = (status = '', message = '', focusElementId = '', callBack) => {

    if (message !== undefined && message !== "") {
        Swal.fire({
            text: message,
            icon: status.toLowerCase(),
            title: status.toUpperCase(),
            confirmButtonColor: '#238914',
            allowOutsideClick: false,
            width: 500,
            didClose: () => {
                if (focusElementId !== "") {
                    document.getElementById(focusElementId)?.focus();
                }
            }
        }).then((result) => {
            if (result.isConfirmed) {
                callBack && callBack();
            }
        });
    }
}

export const setFocus = (ID) => {
    const element = document.getElementById(ID);
    if (element) {
        element.focus()
    }
}

export const FileUploadChild = (props) => {
    const { label = '', message = '', types = [], children } = props;

    return (
        <>
            <i className="fa-solid fa-upload" style={{ color: "#4E2D87" }}></i>
            <div className="sc-eqUAAy file-children ">
                {(message.trim() === '.' || message.trim() === '') ?
                    <span className="sc-fqkvVR" style={{ fontSize: "12px" }}><span style={{ fontSize: "12px" }}>{`Upload ${label}`}</span>{label === "Category Image" || label === "Sub Category Image" || label === "Banner Image" ? "" : "or drop a file right here"} </span> :
                    <span className="sc-fqkvVR" style={{ fontSize: "12px" }}><span className=' text-success'>{`${message}`}</span >Uploaded</span>
                }
                {(message.trim() !== '.' || message.trim() !== '') &&
                    <span style={{ fontSize: "12px" }} title={`types: ${types.join()}`} className="file-types text-info">{types.join()}</span>
                }
                {children}
            </div>
        </>
    );
}



export const ConfirmAlertBox = (status = '', message = '', callback, focusElementId = '',) => {
    if (message !== undefined && message !== "") {
        Swal.fire({
            text: message,
            icon: status.toLowerCase(),
            title: status.toUpperCase(),
            confirmButtonColor: '#238914',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            showCancelButton: true,
            allowOutsideClick: false,
            width: 500,
            didClose: () => {
                if (focusElementId !== "")
                    document.getElementById(focusElementId)?.focus();
            }
        }).then((result) => {
            if (result.isConfirmed) {
                callback(result.isConfirmed);
            } else if (result.isDismissed) {
                callback(false);

            }
        });
        // alert(FindTranslatedKey(message))
    }
}


export const DateBox = (props) => {
    const { divclassname = "col-lg-2 col-md-3 col-sm-6 pe-0 fields", label = '', disabled = false, error, onKeyDown, selected, placeholder, id, onFocus, onChange, required, inputclassname, divstyle, InfoDetails, preventOpenOnFocus = true } = props;
    return (
        <div className={`fields ${divclassname}`} style={divstyle}>
            <div>
                {label === '' ? '' :
                    <label title={label}>{label}
                        {required ? <span style={{ color: "red" }}> *</span> : <></>}
                    </label>
                }
                <DatePicker
                    id={id}
                    className={`form-control ${inputclassname} admin_form-control`}
                    dateFormat="dd-MMM-yyyy"
                    placeholderText={placeholder}
                    selected={selected}
                    onFocus={onFocus}
                    onKeyDown={onKeyDown}
                    disabled={disabled}
                    // inputrefs={inputrefs.current[id]}
                    //isClearable={true}
                    //selectsRange={true}
                    //monthsShown={3 }
                    // onMonthChange={() => { }}
                    showYearDropdown={true}
                    showMonthDropdown={true}
                    //  selectsStart={true }
                    //inline={true }
                    //showIcon={true }
                    //autoFocus={true }
                    //closeOnScroll={true}
                    // showFullMonthYearPicker={true }
                    //  showTwoColumnMonthYearPicker={true }
                    // onDayMouseEnter
                    // focusSelectedMonth={true }
                    //todayButton={true }
                    yearDropdownItemNumber={50}
                    scrollableYearDropdown={true}
                    preventOpenOnFocus={true}
                    onChange={onChange}
                    autoComplete="off"
                    title={required ? 'Please fill this field.' : ''}
                    {...props}
                />
                {error && <div className="error-msg">{error}</div>}
            </div>
        </div>
    )
}

export const SelectInputBox = (props) => {
    const { divclassname = "col-md-3 col-sm-6 fields mt-3",/* divclassname = "col-lg-3 col-md-4 col-sm-6 fields",*/ inputclassname, onKeyDown, divstyle, inputstyle, inputrefs, id, label = '', value, onChange, children, disabled = false, required, ignore_common } = props
    return (
        <div
            //className={` ${divclassname}`}
            className={`${ignore_common ? '' : divclassname}`}
            style={divstyle}
        >
            {label === '' ? '' :
                <label htmlFor={id}>{label || 'Branch'}
                    {required ? <span style={{ color: "red" }}> *</span> : <></>}
                </label>}
            <select
                id={id}
                className={`form-select ${inputclassname} admin_form-control`}
                style={inputstyle}
                onKeyDown={onKeyDown}
                value={value}
                disabled={disabled}
                onChange={onChange}
                title={required ? 'Please select an item in the list.' : ''}
                autoComplete="off"
                {...props}
            >
                {children}
            </select>
        </div>
    )
}

export const Switch = (props) => {

    const { id, checked = false, onKeyDown, label, customstyle, onClick,
        extraclassname = `form-check form-switch`,
        onChange, divclassname = 'col-lg-3 col-md-4 mt-4 col-sm-6' } = props

    return (
        <div className={` ${divclassname}`} key={`checkin${id}`}>
            <div className={`${extraclassname}`} >
                <input
                    style={customstyle}
                    className="form-check-input"
                    type="checkbox"
                    id={id}
                    checked={checked}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    onClick={onClick}
                    //    ref={ref => (inputrefs.current[id] = ref)}
                    {...props}
                />
                {label && <label className="form-check-label" htmlFor={id}>{label}</label>}
            </div>
        </div>
    )
}


export const handleDownloadExcel = async (handleApiUrl, FileName, request, enableAlternateColor = false, enableHeaderColor = true, enableFilters = false) => {

    //let request = {
    //    AdvanceFilter: [{
    //        "SearchingValue": filterData?.SearchFieldValue,
    //        "ComparisonType": filterData?.StartValue,
    //        "SearchingText": filterData?.SearchText,
    //    }],
    //    Type: 'E',
    //    PageIndex: '1',
    //    ...sortConfig,
    //    pageIndex: `${currentPageNo}`
    // pageSize: pageSize
    //}
    try {
        let res = await ApiCall(handleApiUrl, { ...request })
        if (res.Status === "SUCCESS") {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sheet 1', {
                views: [{ state: 'frozen', ySplit: 1 }],
            });

            // Example: Set header row to bold and background color
            const headerRow = worksheet.addRow(res?.HeadersKey?.length > 0
                ? res.HeadersKey.filter((key) => {
                    return res?.HideColumns?.length > 0 ? !res?.HideColumns?.includes(key) : key
                })
                : Array.from(
                    new Set(
                        res?.Data?.flatMap((data) => Object.keys(data))
                            .filter((columnName) => !res?.HideColumns?.includes(columnName))
                    )
                ))

            const headerKey = (res?.HeadersKey?.length > 0 && res?.HeadersValue?.length > 0)
                ? res.HeadersValue.filter((key) => {
                    return res?.HideColumns?.length > 0 ? !res?.HideColumns?.includes(key) : key
                })
                : Array.from(
                    new Set(
                        res.Data?.flatMap((data) => Object.keys(data))
                            .filter((columnName) => !res?.HideColumns?.includes(columnName))
                    )
                );

            headerRow.font = { bold: true };
            if (enableHeaderColor) {
                headerRow.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFF00' },
                };
                headerRow.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                };
            }

            // Example: Enable filters
            if (enableFilters) {
                worksheet.autoFilter = {
                    from: { row: 1, column: 1 },
                    to: { row: 1, column: headerKey.length },
                };
            }

            // Example: Set alternate row background color
            for (let i = 2; i <= res.Data.length + 1; i++) {
                const row = worksheet.getRow(i);

                if (enableAlternateColor && i % 2 === 0) {
                    row.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'DDDDDD' },
                    };
                }

                row.values = res.HeadersValue?.length > 0 ?
                    res.HeadersValue?.map((key) => res.Data[i - 2][key])
                    : headerKey?.map((key) => res.Data[i - 2][key]);
            }

            // Set column widths to fit content
            worksheet.columns.forEach((column) => {
                let maxLength = 0;
                column.eachCell({ includeEmpty: true }, (cell) => {
                    const length = cell.value ? String(cell.value).length : 0;
                    maxLength = Math.max(maxLength, length);
                });
                column.width = maxLength < 12 ? 12 : maxLength;
            });

            const buffer = await workbook.xlsx.writeBuffer();
            const data = new Blob([buffer], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${FileName}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } else if (res.Status === 'ERROR') {
            AlertBox(res.status, res.message, res.focus)
        }
        else if (res.Status === 'UNAUTHORIZED') {
            AlertBox(res.status, res.message, res.focus)
        }
    } catch (err) {
        /* NotificationSound('ERROR', err.message)*/
    }
};

