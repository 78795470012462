import { CloseOutlined, CloudDownloadOutlined, GridViewOutlined, MoreVertOutlined, OpenInNew, OpenInNewOutlined, ShareOutlined, UploadFileOutlined, ViewListOutlined } from '@mui/icons-material'
import { Button, Card, CardHeader, CardMedia, Dialog, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const MarketingTools = () => {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
  };


  return (
    <>
        <div className='d-flex align-items-center gap-3'>
          <h2 className='mb-0'>Product Links</h2>
          <IconButton>
            <OpenInNewOutlined/>
          </IconButton>
        </div>
        <p>Browse the store for qualifying products. Paste the URL below to generate an affiliate link</p>

        <Row className='my-4'>
          <Col md={6} className='mb-3 mb-md-0'>
             <TextField id="outlined-basic" label="Paste product page link" variant="outlined" size='small' fullWidth/>
          </Col>
          <Col md={6}>
             <TextField id="outlined-basic" label="Your generated link" variant="outlined" size='small' fullWidth/>
          </Col>
        </Row>

        <p className='text-black-50'>Share this link on your blog, Facebook, Instagram, etc. When people visit the store using your link, you get commissions on everything they buy</p>

        <div className="history p-3 rounded">
          <div className='d-flex align-items-center justify-content-between'>
             <div className='d-flex align-items-center gap-3'>
                <h4 className='mb-0'>History</h4>
                <IconButton>
                  <OpenInNewOutlined/>
                </IconButton>
             </div>
              <Button variant="contained" color="secondary"  size="small" onClick={handleClickOpen}>
                Manage Collection
              </Button>
          </div>
          <Row className='mt-4'>
            <Col md={4}>
              <Card fullWidth>
                <CardMedia 
                    component="img"
                    height="220"
                    image="./images/indonessia_card.webp"
                    alt="Paella dish"
                  />
                <CardHeader
                  action={
                    <IconButton aria-label="settings">
                      <MoreVertOutlined />
                    </IconButton>
                  }
                  subheader="Azerbaijan AZN_01GBX07DAYS"
                />
                <p className='text-black-50 px-3 text-wrap'>https://uat.simfly.co.in/plans/Azerbaijan</p>
              </Card>
            </Col>
            <Col md={4}>
              <Card fullWidth>
                <CardMedia 
                    component="img"
                    height="220"
                    image="./images/indonessia_card.webp"
                    alt="Paella dish"
                  />
                <CardHeader
                  action={
                    <IconButton aria-label="settings">
                      <MoreVertOutlined />
                    </IconButton>
                  }
                  subheader="Azerbaijan AZN_01GBX07DAYS"
                />
                <p className='text-black-50 px-3 text-wrap'>https://uat.simfly.co.in/plans/Azerbaijan</p>
              </Card>
            </Col>
          </Row>
        </div>
 
        <div className="media_asset mt-4">
          <div className='d-flex align-items-center justify-content-between'>
                <h4 className='mb-0'>Media Assets</h4>
             <div className='d-flex align-items-center'>
                <IconButton sx={{backgroundColor:'purple'}}>
                  <GridViewOutlined sx={{color:'#fff'}}/>
                </IconButton>
                <IconButton>
                  <ViewListOutlined/>
                </IconButton>
             </div>
          </div>
          <Row className='mt-4'>
            <Col md={4}>
              <Card fullWidth>
                <CardMedia 
                    sx={{objectFit:'contain'}}
                    component="img"
                    height="220"
                    image="./logo_final.svg"
                    alt="Paella dish"
                  />
                  <div className="d-flex p-3 justify-content-between align-items-center">
                    <h6 className="mb-0">
                      Brand Logo
                    </h6>
                    <div className='d-flex align-items-center'>
                        <IconButton size="small">
                          <ShareOutlined fontSize='small'/>
                        </IconButton>
                        <IconButton size="small">
                          <CloudDownloadOutlined fontSize='small'/>
                        </IconButton>
                    </div>
                  </div>
              </Card>
            </Col>
            </Row>
        </div>

        <SimpleDialog 
         open={open}
         onClose={handleClose}
        />
    </>
  )
}



export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };


  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md">
      <DialogTitle className='d-flex justify-content-between align-items-center border-bottom'>Manage collections 
        <IconButton onClick={handleClose}>
          <CloseOutlined/>
        </IconButton>
      </DialogTitle>
       <DialogContent>
       <p className='mt-2'>Collections page banner</p>
        <label htmlFor="upload" className='text-center p-3 bg-light mb-3 w-100'>
            <input type="file" id='upload' className='d-none' />
            <UploadFileOutlined color="secondary" sx={{fontSize:'55px',opacity:.5}}/>
            <p>Drag & Drop some files here, or click to select files</p>
        </label>
        {/* <p>Collections page bio</p> */}
        <TextField id="outlined-basic" label="Collections page bio" variant="outlined" size='small' fullWidth/>
       </DialogContent>
    </Dialog>
  );
}