import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AlertBox, ApiCall } from "../CommonSetting/Config";

// import BackgroundImage from "../../assets/images/background.png";
// import Logo from "../../assets/images/logo.png";

const ForgotPassword = () => {
    const [inputUsername, setInputUsername] = useState("");
    const [loading, setLoading] = useState(false);

    const handlePassword = async (e) => {
        e.preventDefault()
        let req = {
            UserCode: inputUsername,
            Token: 'TBHWG-SIPL-GVTF7-SIMFLY-JQMKV'
        }
        try {
            setLoading(true)
            const response = await ApiCall(`/api/Affiliate/ForgetPassword`, req);

            if (response.status === 'SUCCESS') {
                AlertBox('SUCCESS', response.message, response.focus)
                setInputUsername('')
                setLoading(false)

            } else if (response.status === 'ERROR') {
                AlertBox('ERROR', response.message, response.focus)
                setLoading(false)

            }
        } catch (error) {
            AlertBox('ERROR', 'There was a problem with the server, Please try again later')
            setLoading(false)

        }
    }

    return (
        <div
            className="sign-in__wrapper"
        >
            {/* Overlay */}
            <div className="sign-in__backdrop"></div>
            {/* Form */}
            <Form className="shadow p-4 bg-white rounded">
                {/* Header */}
                <div className="h4 mb-2 text-center">Reset Your Password Here</div>

                <Form.Group className="mb-3 mt-3" controlId="username">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                        type="text"
                        value={inputUsername}
                        placeholder="Email address"
                        onChange={(e) => setInputUsername(e.target.value)}
                        id='txtEmailID'
                        required
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handlePassword(e)
                            };
                        }}
                    />
                </Form.Group>

                {!loading ? (
                    <Link className="w-100 btn btn-primary" variant="primary"
                        onClick={(e) => handlePassword(e)}
                        type="submit">
                        Reset
                    </Link>
                ) : (
                    <Button className="w-100" variant="primary" type="submit" disabled
                        onClick={(e) => handlePassword(e)}
                    >
                        Reseting...
                    </Button>
                )}
                <div className="d-grid justify-content-center">
                    <Link
                        className="text-muted px-0"
                        to='/'
                    >
                        Login
                    </Link>
                </div>
            </Form>
            <p className="mt-4">Don't Have account? <Link to=''>Create Account</Link></p>
        </div>
    );
};

export default ForgotPassword;
