import { GridViewOutlined, ViewListOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { Col, Container, Row } from 'react-bootstrap'
import { CommonTable } from './CommonTable'
import { AlertBox, ApiCall, dateFormateWithTime, handleDownloadExcel } from '../../CommonSetting/Config';
import { DraftsRounded } from '@mui/icons-material';
import Loader from '../Common/Loader';
import { affliateSessionExpire } from '../../redux/authSlice';
import { useDispatch } from 'react-redux';
import { NewTable } from '../Common/NewTable';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const Payments = () => {
    const [loading, setLoading] = useState(true)
    const [paymentDetails, setPaymentdetails] = useState({
        amountPaid: '',
        amountPending: '',
        amountPendingDuein30days: '',
    })
    const [tableData, setTableData] = useState([]);
    const dispatch = useDispatch()

    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ sortColumn: null, sortOrder: null });
    const [pageSize, setPageSize] = useState("10");
    const [isLoading, setIsLoading] = useState(false);
    const [yearDropdown, setYearDropdown] = useState([]);
    const [filterData, setFilterData] = useState({
        type: "S",
        searchText: "",
        searchFieldValue: "",
        startValue: "",
        AdvanceFilter: []
    })
    const [fileName, setFileName] = useState()
    const [formData, setFormData] = useState({
        year: ''
    })

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    const handleChange1 = (event, pagenumber) => {
        setCurrentPageNo(pagenumber)
    }

    const GetPaymentsDetails = async () => {
        let request = {
            ...filterData,
            ...sortConfig,
            pageIndex: `${currentPageNo}`,
            pageSize: pageSize,
            year: String(formData.year)
        }
        try {
            const response = await ApiCall(`/api/Affiliate/GetPaymentsDetails`, request);
            if (response.Status === "SUCCESS") {
                setTableData(response)
            } else if (response.Status === "ERROR") {
                setTableData([])
                //AlertBox(response.Status, response.Message)
            } else if (response.Status === "EXPIRED") {
                dispatch(affliateSessionExpire({
                    affliateSessionExpires: true,
                    status: response.Status,
                    message: response.Message
                }))
            }
        } catch (error) {
            AlertBox('ERROR', 'There was a problem with the server, Please try again later')
        }
        finally {
            setLoading(false)
        }
    }

    const yearDropdownList = async () => {
        let request = {
            "Type": "GetYear",
        }
        try {
            setIsLoading(true)
            let res = await ApiCall('/api/Affiliate/GetMasterData', { ...request })
            if (res.status === 'SUCCESS') {
                setYearDropdown(res.dataList);
                setFormData({ ...formData, year: res?.dataList[0]?.code });
            } else if (res.status === 'EXPIRED') {
                dispatch(affliateSessionExpire({
                    affliateSessionExpires: true,
                    status: res.status,
                    message: res.message
                }))
            }
            else if (res.status === 'ERROR') {
                setYearDropdown([])
            }
        }
        catch (error) {
            AlertBox('ERROR', 'Failed to show the data. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const GetPayments = async () => {
        let request = {
            year: String(formData.year)
        }
        try {
            const response = await ApiCall(`/api/Affiliate/GetPayments`, request);
            if (response.status === "SUCCESS") {
                setPaymentdetails({
                    ...paymentDetails,
                    amountPaid: response.amountPaid,
                    amountPending: response.amountPending,
                    amountPendingDuein30days: response.amountPendingDuein30days,
                })
            } else if (response.status === "ERROR") {
                setTableData([])
                AlertBox(response.status, response.Message)
            } else if (response.status === "EXPIRED") {
                dispatch(affliateSessionExpire({
                    affliateSessionExpires: true,
                    status: response.Status,
                    message: response.Message
                }))
            }
        } catch (error) {
            AlertBox('ERROR', 'There was a problem with the server, Please try again later')
        }
        finally {
            setLoading(false)
        }
    }
    const handleDownLoadExcel = () => {
        let request = {
            AdvanceFilter: [{
                "SearchingValue": filterData?.SearchFieldValue,
                "ComparisonType": filterData?.StartValue,
                "SearchingText": filterData?.SearchText,
            }],
            Type: 'E',
            PageIndex: '1',
            ...sortConfig,
            pageIndex: `${currentPageNo}`,
            pageSize: pageSize
        }
        handleDownloadExcel('/api/Affiliate/GetPaymentsDetails', fileName, request)
    }

    useEffect(() => {
        document.title = 'Payments'
        setFileName(`Payments ${dateFormateWithTime(new Date())}`)
        GetPayments()
    }, [formData.year])

    useEffect(() => {
        yearDropdownList();
    },[])


    useEffect(() => {
        GetPaymentsDetails()
    }, [currentPageNo, sortConfig, pageSize, formData.year])

    return (
        <>
            <div className="main">
                <Container>
                    <Box sx={{ bgcolor: 'background.paper', }} className="tab_box px-4 py-3" fullwidth>
                        <div className="Payments">
                            <div className="sum_head d-md-flex justify-content-between align-items-center">
                                <div className='d-flex gap-3'>
                                    <h3 className='mb-md-0'>Payments</h3>
                                    <FormControl fullWidth className='col-12' size='small'>
                                        <InputLabel id="demo-simple-select-label">Year</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={formData.year}
                                            label="Year"
                                            onChange={(e) => setFormData({ ...formData, year: e.target.value })}
                                        >
                                            {
                                                yearDropdown?.map((item, index) => {
                                                    return (
                                                        <MenuItem key={index} value={item.code}>{item.name}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>

                            </div>
                            <Row className='mt-4'>
                                <Col md={4} className='mb-3'>
                                    <div className="text-center p-3 rounded" style={{ backgroundColor: '#ffebb7' }}>
                                        <h3>{paymentDetails.amountPaid}</h3>
                                        <p className='mb-0'>Amount Paid</p>
                                    </div>
                                </Col>
                                <Col md={4} className='mb-3'>
                                    <div className="text-center p-3 rounded" style={{ backgroundColor: '#ffebb7' }}>
                                        <h3>{paymentDetails.amountPending}</h3>
                                        <p className='mb-0'>Amount Pending</p>
                                    </div>
                                </Col>
                                <Col md={4} className='mb-3'>
                                    <div className="text-center p-3 rounded" style={{ backgroundColor: '#ffebb7' }}>
                                        <h3>{paymentDetails.amountPendingDuein30days}</h3>
                                        <p className='mb-0'>Due in 30 days</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className="details mt-4">
                            <div className='d-flex align-items-center justify-content-between'>

                                <h4 className='mb-0'>Details</h4>


                                <div className='d-flex align-items-center d-none'>
                                    <IconButton sx={{ backgroundColor: 'purple' }}>
                                        <GridViewOutlined sx={{ color: '#fff' }} />
                                    </IconButton>
                                    <IconButton>
                                        <ViewListOutlined />
                                    </IconButton>
                                </div>
                            </div>
                            <CommonTable />
                        </div>
                        {
                            tableData && tableData?.length !== 0 ?
                                <NewTable
                                    PageName={'Transaction'}
                                    handleFilter={() => {
                                        GetPaymentsDetails()
                                        setFilterData({
                                            ...filterData,
                                        })
                                    }}
                                    Filter={false}
                                    filterData={filterData}
                                    tableData={tableData}
                                    setFilterData={setFilterData}
                                    currentPageNo={currentPageNo}
                                    handleChange1={handleChange1}
                                    handleSorting={handleSorting}
                                    sortConfig={sortConfig}
                                    exportExcelData={true}
                                    handleDownLoadExcel={handleDownLoadExcel}
                                />
                                :
                                <div className="p-3 bg-light w-100 text-center">
                                    <DraftsRounded sx={{ fontSize: '40px' }} />
                                    <p>No Record Found</p>
                                </div>
                        }
                    </Box>
                </Container>
            </div>
            {loading && <Loader />}
        </>
    )
}

export default Payments